.otp-boxes{
    justify-content: center;
    align-items: center;
}
.otp-boxes input{
    border: none;
     border-bottom: 2px solid black;
}
.otp-boxes input:focus-visible{
    outline: none;
}

.mt-24{
    margin-top: 24px;
}

.login-agent-input{
    border-bottom: 1px solid #707070 !important;
    border: none;
    color: #EC743F;
    font-size: 16px;
    line-height: 15px;
    padding: 4px;
    margin-bottom: 21px;
    width: 100%;
}
.login-agent-input{
    outline: none;
}
.login-p{
    margin-bottom: 25px;
}
.login-box{
    width: 70%;
    margin: auto;
    text-align: left;
}
h3.login-heading {
    text-align: center;
    font-size: 26px;
}

h3.login-heading span{
    color: #EC743F;
    font-weight: bold;
}