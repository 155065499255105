.dashboard-component {
    .page-main-heading {
        text-align: left;
        font-size: 22px;
        margin-bottom: 20px;
    }
    .page-main-heading::after {
        content: '';
        border-bottom: 2px solid #e46634;
        height: 2px;
        width: 100px;
        position: absolute;
        top: 52px;
        left: 22px;
    }

    .workspace-dynamic-heading {
        font-size: 18px;
        text-align: left;
        font-weight: 700;
        letter-spacing: 0.30px;
    }
    .workspace-dynamic-sub-heading {
        font-size: 15px;
        text-align: left;
        font-weight: 500;
        letter-spacing: 0.30px;
        line-height: 30px;
    }
    .work-dynamic-icon-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .work-dynamic-icon {
        width: 20px;
    }
    p.work-dynamic-text {
        font-size: 14px;
        padding-left: 8px;
        padding-right: 12px;
        text-align: left;
        color: #575757;
    }
    .dash-dynamic-container {
        padding: 0px;
        display: flex;
        margin-top: 10px;
    }
    .dark-dash-dynamic-container {
        padding: 0px;
        display: flex;
        margin-top: 5px;
        background-color: #d5d5d5;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        padding-bottom: 4px;
        padding-left: 20px;
        bottom: 0px;
        position: absolute;
    }
    .work-dynamic-call-icon {
        width: 20px;
        margin-right: 50px;
    }
    .workspace-dynamic-main {
        box-shadow: 0px 0px 5px 0px #00000012;
        border-left: 4px solid #EC743F;
        border-radius: 7px;
        height: 180px;
        position: relative;
    }
    .workspace-dynamic-inner {
        padding: 20px 20px;
    }

    .workspace-dynamic-box{
        margin-top: 5px;
        margin-bottom: 25px;
        padding: 0px;
    }
    .main-heading-background{
        background-color: #fff;
        padding: 15px 20px;
        border-radius: 10px;
        position: relative;
    }
    
}