.Sidebar-View {
  .container {
    max-width: 1100px;
  }

  .BodySection {
    width: 100%;
    float: left;
  }

  .wrapper {
    width: 230px;
    position: fixed;
    top: 68px;
    left: -1px;
    height: 100%;
    z-index: 999;
    background: transparent linear-gradient(180deg, #EC743F 0%, #FF7D44 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    transition: all 0.3s;
  }

  .logo-container {
    padding: 13px 0px;
    text-align: center;
  }

  .logo_img {
    width: 62px;
    height: 62px;
  }

  .sidebar-container {
    padding: 18px 0px;
  }
  .nav-container {
    display: flex;
    align-items: center;
    padding: 11px 22px 11px 22px;
    height: 44px;
    margin-bottom: 2px;
  }
  .nav-icon {
    width: 22px;
    height: auto;
  }

  .nav-link-container {
    padding: 0 12px;
  }

  .nav-link {
    background: transparent;
    border: none;
    color: #ffffff;
    padding: 0;
    font-size: 15px;
    line-height: 18px;
  }

  .nav-link:focus {
    outline: none;
  }

  .decoration-none:hover {
    text-decoration: none;
  }
  .nav-container:hover{
    background: #F48D5F;
  }

  .sidebar-container .active {
    background: #F48D5F;
  }

  img.close-btn {
    display: none;
  }

  img.sidebar-logo {
    display: none;
  }
  
  @media (max-width: 600px) {
    img.close-btn {
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 40px;
      margin-bottom: 12px;
      margin-left: 210px;
      margin-top: -2px;
      object-fit: contain;
      padding: 8px;
      text-align: right;
      width: 40px;
      float: left;
    }

    .wrapper {
      top: 0px !important;
      background: #00000040!important;
      width: 100%;
    }

    .sidebar-container {
      padding: 33px 0px;
      width: 233px;
      background: transparent linear-gradient(180deg, #EC743F 0%, #FF7D44 100%) 0% 0% no-repeat padding-box;
      height: 100%;
    }

    img.sidebar-logo {
      background-color: #fff;
      border-radius: 0 25px 25px 0;
      display: block;
      height: 48px;
      margin-bottom: -41px;
      margin-right: 142px;
      margin-top: -24px;
      object-fit: contain;
      padding: 8px;
      width: 115px;
    }
  }
}