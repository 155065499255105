body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E7E7EB;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, label{
  margin: 0px ;
  padding: 0px;
}

.progressBar-container{
  background-color: #FFFFFF;
  justify-content: space-between;
}

.bg-lightgrey{
  background-color: #F5F5F5;
}

.bg-white{
  background-color: #FFFFFF;
}

.RightArea {
  width: calc(100% - 230px);
  padding: 30px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 68px;
  right: 0;
  background-color: #E7E7EB;
}

.form-box {
  box-shadow: 0px 0px 5px 0px #00000012;
  border-left: 4px solid #EC743F;
  border-radius: 7px;
  padding: 20px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checks{
  display: inline!important;
  text-align: left!important;
  padding-left: 0px!important;
}

.checks-input{
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}
.radio-inline{
  width: auto!important;
  margin-left: -10px;
}

.form-box P {
  word-break: break-all;
}

img.excel-icon {
  width: 35px;
}


@media (max-width: 600px) {
  .Sidebar-View .wrapper {
    display: none!important;
    top: 0px;
  }
  .RightArea {
    width: 100%!important;
    padding: 30px 20px;
    min-height: auto!important;
}

.wrapper.active {
  display: block!important;
  transition: all 0.3s;
}

.form-row {
  display: block!important;
}

.submit.downbtn{
  margin-bottom: 10px;
}
.exeboxsearch {
  justify-content: end!important;
}
}