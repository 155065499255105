.dashboard-container {
    .dashboard-area {
        position: relative;
    }

    .datepicker-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .datepicker-input input {
        width: 100%;
        border-radius: 3px;
        border: 1px solid rgb(112 112 112 / 52%);
        background-color: transparent;
        padding: 10px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        width: 170px;
    }

    .datepicker-input input:focus-visible {
        outline: none;
    }

    .datepicker-input label {
        font-size: 16px;
        color: #72738C;
        line-height: 16px;
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 4px;
        background-color: #fff;
    }

    .datepicker-container {
        position: relative;
    }

    .icon-box-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #f9f9f9;
        padding: 18px;
        border-radius: 10px;
        margin-bottom: 18px;
    }

    img.dash-icon {
        height: auto;
        width: 50px;
    }

    .icon-box-sub-heading {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.56px;
        text-align: left;
        color: #575757;
        padding-top: 4px;
    }

    .icon-box-heading {
        text-align: left;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0.56px;
    }

    .content {
        margin-left: 10px;
    }

    .dash-container {
        margin-top: 0px;
    }

    p.last-update-text {
        font-size: 16px;
        text-align: left;
        color: #434466;
        font-weight: 500;
        padding-top: 15px;
    }
    .second-tab {
        margin-top: 20px;
    }

    img.next-icon {
        width: 24px;
    }

    .work-icon-box-sub-heading {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.56px;
        color: #575757;
        text-align: left;
    }

    .work-icon-box-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        padding: 12px 18px;
        border-radius: 10px;
        border-bottom: 2px solid #cecece;
    }
    .work-icon-box-heading {
        font-size: 18px;
        text-align: left;
        padding-bottom: 5px;
    }

    .workrow1{  
        margin-top: 0px;
    }
    .workrow2{  
        margin-top: 20px;
    }
    .workrow3{  
        margin-top: 20px;
    }

    .loan-subheading {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
        color: #e46634;
    }
    button.filebtn {
        border: 0px;
        background-color: transparent;
        outline: 0px;
    }

    @media(max-width:600px){
        p.last-update-text {
            font-size: 14px;
        }

        .icon-box-container {
            display: block;
            padding: 12px;
        }
        img.dash-icon {
            height: auto;
            width: 32px;
        }

        .content {
            margin-left: 0px;
            margin-top: 5px;
        }
    }
}