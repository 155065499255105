:root {
    --primary: #1C1E3B;
    --secondary: #EC743F;
    --warning: #ffd0228;
    --danger: #eb3f27;
    --success: #75fa83;
    --white: #fdfdfd;
    --dark: #181717
}

button:focus{
    outline: none !important;
    box-shadow: none !important;
}

.btn-ugro{
   font-weight: 400;
   border-radius: 10px;
   cursor: pointer;
   transition: transform 0.3s ease;
}

.btn-ugro:hover{
    transform: translateY(-2px);
}

//Button color and style

.btn--primary--solid {
    background-color: var(--primary);
    color: var(--white);
    border: none;
}

.btn--warning--solid {
    background-color: var(--warning);
    color: var(--dark);
    border: none;
}

.btn--danger--solid {
    background-color: var(--danger);
    color: var(--white);
    border: none;
}

.btn--success--solid {
    background-color: var(--success);
    color: var(--white);
    border: none;
}
.btn--secondary--solid {
    background-color: var(--secondary);
    color: var(--white);
    border: none;
}


.btn--primary--outline {
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(var(--primary));
}

.btn--warning--outline {
    background-color: transparent;
    border: 2px solid var(--warning);
    color: var(--warning);
}

.btn--danger--outline {
    background-color: transparent;
    border: 2px solid var(--danger);
    color: var(--danger);
}

.btn--success--outline {
    background-color: transparent;
    border: 2px solid var(--success);
    color: var(--success);
}

.btn--secondary--outline {
    background-color: transparent;
    border: 2px solid var(--secondary);
    color: var(--secondary);
}



//Button sizes

.btn--medium {
    padding: 0px 20px;
    font-size: 18px;
    height: 36px;
}

.btn--large {
    padding: 0px 20px;
    font-size: 16px;
    height: 36px;
}

.mr-10{
    margin-right: 10px;
}

.full-btn{
    width: 100%;
    font-size: 16px;
}
