.highlight-case{
    font-weight: bold;
    color: #EC743F;
}
.row.all-row.chatbox {
    border: 1px solid #e4dfdf;
    border-radius: 10px;
    padding: 10px 0px;
    margin: 10px 0px;
}

.Attachment-Link{
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #ec743f;
    font-family: "Helvetica, Bold";
    margin-bottom: 0px;
    word-break: break-all;
    text-decoration: underline;
}
.loan-subheading {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    color: #000 !important;
}

.Activity-sub-text{
    font-weight: bold;
    font-size: 15px;
}
