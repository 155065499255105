.dashboard-card-container {
  .dashboard-area {
    position: relative;
  }

  .card-container {
    padding: 0px;
    margin-top: 20px;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .content-row {
    padding-left: 15px;
    padding-right: 15px;
  }

 .addcasebtn {
    margin-right: 15px;
}

.filter-row {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exebtn {
  background-color: #1D6F42;
    color: #ffffff;
    padding: 8px 18px;
    font-size: 16px;
    border-radius: 10px;
}

.exebox {
  display: flex;
}

.exebtnbox {
  margin-top: 6px;
}

i.fa.fa-file-excel-o {
  margin-right: 5px;
}

  .table-bordered td,
  .table-bordered th {
    border: 0px solid #dee2e6;
  }

  .has-search .form-control {
    padding-left: 2.375rem;
    display: block;
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-bottom: 3px solid #e5e5e5;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e5e5e5;
    outline: 0;
    box-shadow: 0 0 0 0rem #e5e5e5;
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 44px;
    text-align: center;
    pointer-events: none;
    color: #575757;
  }

  img.actionIcon {
    width: 26px;
    height: auto;
    object-fit: contain;
  }

  .data-table {
    border-collapse: collapse;
  }

  .data-table tbody {
    border: 1px solid rgba(112, 112, 112, 0.16);
  }

  .data-table tbody tr {
    border-bottom: 2px solid rgba(112, 112, 112, 0.16);
  }

  .data-table.stripe tbody tr.odd,
  table.data-table.display tbody tr.odd {
    background-color: transparent;
  }

  .data-table.display tbody tr.odd>.sorting_1,
  table.data-table.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color: transparent;
  }

  .data-table.display tbody tr.even>.sorting_1,
  table.data-table.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: transparent;
  }

  .data-table tr td {
    border: none;
    color: #000000;
    font-size: 16px;
  }

  .data-table thead {
    background-color: rgba(103, 103, 103, 0.07);
    border-bottom: 2px solid rgba(112, 112, 112, 0.16);
  }

  .data-tables_wrapper .data-tables_length {
    float: left;
    margin-bottom: 20px;
  }

  .primary-theme {
    color: #EC743F;

  }

  .datepicker-container {
    position: relative;
  }

  .datepicker-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .datepicker-input input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgb(112 112 112 / 52%);
    background-color: transparent;
    padding: 10px;
    height: 40px;
    font-size: 14px;
    line-height: 16px;
    width: 170px;
  }
  
  .datepicker-input input:focus-visible {
    outline: none;
  }
  
  .datepicker-input label {
    font-size: 16px;
    color: #72738C;
    line-height: 16px;
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0px 4px;
    background-color: #fff;
  }
  
.datepicker-input select{
  border-radius: 3px ;
  border: 1px solid rgba(112, 112, 112, 0.52);
  background-color: transparent;
  padding: 0px 10px;
  height: 40px;
  font-size: 14px;
  line-height: 16px;
  width: 170px;
}
.datepicker-input select:focus-visible{
  outline: none;
}

.mob-filter-btn {
  display: none;
}

.filter-btn-show{
  display: none;
}

.card-body {
  padding: 0rem; 
}

.card {
  border: 0px solid rgba(0,0,0,.125);
}

.filter-show-hide{
  background-color: #ec743f !important;
  border: #ec743f !important;
  color: #fff;
  text-decoration: none;
  padding: 8px 0px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.filter-show-hide:hover{
  color: #fff;
  text-decoration: none;
}

.filter-btn{
  background-color: #ec743f !important;
  border: #ec743f !important;
  color: #fff;
  text-decoration: none;
  padding: 8px 0px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.filter-btn:hover{
  color: #fff;
  text-decoration: none;
}


@media(max-width:600px){
  .filter-row {
    display: block;
    padding-top: 0px;
}

.datepicker-input {
 display: block; 
 margin-top: 20px;
}

.exebox {
  justify-content: space-between;
}

.filter-hide{
  display: none;
}

.filter-btn-hide{
  display: none;
}

.filter-show{
  display: block;
}

.filter-show {
  display: block;
}

  .mob-filter-btn {
    background-color: #ec743f!important;
    border: #ec743f!important;
 }

 .mob-filter-btn-show {
  display: block;
}

.filter-btn-show{
    display: block;
    background-color: #ec743f!important;
    border: #ec743f!important;
}

.filter-btn-hide{ 
  display: none;
}

.searchBox {
  margin-top: 15px;
}

.mob-filter-btn:focus {
  box-shadow: 0 0 0 0rem rgb(38 143 255 / 50%)!important;
}

.select-filter-drop {
  width: 100%!important;
  margin-bottom: 20px!important;
}

.filter-select-box {
  border-radius: 4px;
  padding: 0px 0px!important; 
}

.TabFilterBox {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  width: 100%;
  left: 0px;
  background-color: #fff;
  padding: 15px 18px;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 0px #0000001f;
}

.datepicker-input select {
  width: 100%;
  margin-bottom: 20px;
}

.datepicker-input input{
  width: 100%;
  margin-bottom: 20px;
}

.collapse:not(.show) {
  display: none!important;
}

.card-container {
  margin-bottom: 50px;
}

}
}