.HeaderSection {
  width: 100%;
  z-index: 999;
  height: 68px;
  align-items: center;
  display: flex;
  position: fixed;
  background-color: #FFFFFF;
}

.HeaderSection {
  .logo {
    width: 92px;
    height: auto;
  }

  .dropdown {
    display: inline-block;
    position: relative;
    animation-duration: 0.25s;
    animation-fill-mode: both;
  }

  .dropdown-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
  }

  .dropdown-view-contant {
    text-align: left;
    padding-right: 12px;
  }

  .name-view {
    font-size: 16px;
    color: #000000;
  }

  .admin-contant {
    font-size: 10px;
    color: #000000;
    opacity: 0.5;
  }


  .dropdown-icon-view {
    width: 10px;
    height: 7px;
  }

.homeuser.details {
  width: 46px;
  background-color: #e6e6e6;
  padding: 9px;
  border-radius: 50%;
  height: 46px;
}

  .custom-dropdown-style .dropdown-menu {
    width: 300px;
    border: 0px;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%)!important;
    border-radius: 10px!important;
    height: 240px;
    transform: translate3d(0px, 46px, 0px)!important;
    border: 2px solid #d4d4d4!important;
    
  }

  .login-user-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 15px;
    margin-top: -30px;
  }

  p.user-name {
    font-size: 16px;
    color: #202124;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 8px;
}

p.user-email {
  font-size: 15px;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 6px 10px;
  border-radius: 30px;
  color: #676767;
  text-align: center;
}

.logout-box {
  border-top: 1px solid #FF7D44;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

a.user-logout {
  font-size: 15px;
  border: 1px solid #d2d2d2;
  padding: 4px 10px 6px 10px;
  color: #676767;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 20px!important;
  display: block;
  width: 100px;
  margin: auto;
  font-size: 15px;
  color: #202124;
  font-weight: 500;
}

a.user-logout:hover {
  text-decoration: none;
}

  .dropbtn {
    background-color: #E7E7EB;
    color: #323031;
    padding: 0px 12px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    letter-spacing: 0;
    border-radius: 0;
    width: 180px;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-radius: 2px;
    // font-family: "Helvetica, Regular";
  }

  .homeuser {
    margin-top: -2px !important;
    margin-right: 10px !important;
  }

  .left {
    margin-left: 34px !important;
  }
  
.dropdown-item.active{
  color: #fff!important;
    text-decoration: none;
    background-color: #ea652b;
}

.dropdown-menu {
  padding: 0!important;
  margin: 0!important;
}
.dropdown-item.active, .dropdown-item:active{
  color: #fff !important;
  text-decoration: none;
  background-color: #ea652b
}

.logoutButton {
  background-color: #fa7a48;
  border: 0px;
  border-bottom: 3px solid #e64606;
  width: 100px;
  float: right;
  color: #ffffff;
  border-radius: 4px;
  padding: 6px 25px;
}

.logoutButton:hover{
  background-color: #7e7fa4;
  color: #ffffff;
  border: 0px;
  border-bottom: 3px solid #434466;
}
.pr-30{
  padding-right: 30px;
}

.col-xs-6 {
  width: 50%;
}
.logo-container {
  display: flex;
  align-items: center;
}
.mobile-menu{
  display: none;
}

p.dropdown-item.user-email {
  text-align: left;
  padding: 10px 8px;
  font-size: 14px;
  word-break: break-all;
  white-space: inherit;
  border-bottom: 1px dashed #cdc8c8;
}

.homecross {
  width: 15px;
  height: auto;
  display: block;
  width: 1000;
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 260px;
  cursor: pointer;
}

@media(max-width:600px) {
  .mobile-menu {
    display: block;
    margin-right: 20px;
  }
  .mobile-memu-img {
    width: 26px!important;
    height: 26px!important;
    object-fit: contain;
  }
  .mobile-menu {
    background-color: #fff;
    box-shadow: 0px 0px 7px 0px #00000054;
    padding: 7px 8px!important;
    border-radius: 50%;
    cursor: pointer;
  }
  .logo-img {
    margin-top: 2px;
  }
  .dropbtn {
    width: 100%;
    margin-top: 3px;
    padding: 0px 8px!important;
  }

  .dropdown-menu {
    transform: translate3d(-147px, 50px, 0px) !important;
}

 
}
}

.fixHeaderDropdown{
  left: calc(100% - 300px) !important;
}