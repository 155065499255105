.applicationdetails-container {
    .dashboard-area {
        position: relative;
    }

    .dash-container {
        padding: 0px;
        margin-top: 0px;
    }

    .form-control {
        display: block;
        width: 100%;
        height: 45px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        border-bottom: 3px solid #e5e5e5;
    }

    input#fileToUpload {
        display: block;
        width: 100%;
        height: 45px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        border-bottom: 3px solid #e5e5e5;
        padding: 6px 15px;
    }

    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #e5e5e5;
        outline: 0;
        box-shadow: 0 0 0 0rem #e5e5e5;
    }
    .workspace-dynamic-box{
        margin-top: 10px;
        margin-bottom: 20px;
    }
    label {
        text-align: left!important;
        display: block;
        padding-bottom: 5px;
        font-weight: 500;
        padding-left: 10px;
    }

    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat 95% center;
        background-size: 14px;
    }
    .loan-subheading {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
        color: #e46634;
    }

    .form-row {
        margin-bottom: 15px!important;
    }

    .form-row-up {
        margin-bottom: 0px!important;
        margin-right: -5px;
        margin-left: -5px;
    }

    .form-row-up>.col, .form-row>[class*=col-] {
        padding-right: 5px;
        padding-left: 5px;
    }

    .form-check-input {
        position: relative;
        margin-top: 0px;
        margin-left: 0rem;
        margin-right: 10px;
        padding-right: 10px;
        width: 16px;
        height: 16px;
    }

    label.form-check-label {
        font-size: 18px;
        padding-top: -4px;
        margin-top: 10px;
    }

    button.form-control.loan-btn {
        background-color: #fa7a48;
        color: #ffffff;
        border: 0px;
        border-bottom: 3px solid #e64606;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.56px;
        width: 190px;
        float: right;
        margin-top: 30px;
    }
    button.form-control.loan-btn:hover{
        background-color: #7e7fa4;
        color: #ffffff;
        border: 0px;
        border-bottom: 3px solid #434466;
    }

    .filebox {
        display: flex;
        justify-content: space-between;
        border: 1px solid #cecece;
        padding: 6px 20px;
        border-radius: 4px;
        margin-top: 10px;
        align-items: center;
        background-color: #f4f4f4;
    }

    i.fa.fa-times-circle {
        color: #e46634;
        font-size: 20px;
    }

    p.filename {
        font-size: 16px;
        color: #e46634;
    }
    button.filebtn {
        border: 0px;
        background-color: transparent;
        outline: 0px;
    }

    .submitButton{
        display: contents;
        float: right;
    }
    .textarea-box{
        height: 120px !important;
    }
    .verified-color{
        color: green;
    }

    .single-button {
        margin-top: 29px;
    }
    .verified-icon {
        width: 36px;
        margin-left: 10px;
        margin-right: 3px;
    }

    .input-file{
        width: 0.1px;
        height: 0.1px;
        z-index: -1;
        position: relative;
        overflow: hidden;
    }

    .remove-btn {
        width: 16px;
        margin-left: 10px;
        cursor: pointer;
      }

    .text-red{
        color: red;
    }

    span.checkBtn {
        font-size: 16px;
        padding-top: -4px;
        margin-top: 10px;
        font-weight: 500;
    }

    .checkBorder{
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-bottom: 3px solid #e5e5e5;
        height: 45px;
        padding: 0.375rem 0.75rem;
    }

    @media(max-width:600px){
        .single-button {
            margin-top: 0px;
            display: flex;
            align-items: center;
        }

        label {
            padding-left: 0px;
            padding-bottom: 10px;
        }
        .mob-large-btn{
            padding: 0px 11px;
            font-size: 15px;
        }

        .form-control {
            margin-bottom: 10px;
        }
    }
    
}