.Login-container{
    .LoginForm-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    .LoginForm{
        background-color: #fff;
        opacity: 1;
        padding: 25px 10px;
        width: 591px;
        position: absolute;
        right: 42%;
        box-shadow: 0px 3px 6px #00000029;
    }
    .login-background{
        object-fit: cover;
    }
    .logo {
        width: 162px;
        height: auto;
    }
    .login-header{
        margin-top: 30px;
    }
    .top-heading{
        margin-bottom: 34px;
        font-size: 30px;
        color: #242642;
        line-height: 27px;
    }
    .sub-heading{
        font-size: 16px;
        color: #242642;
        line-height: 15px;
        margin-bottom: 22px;
    }
   .login-input{
        border-bottom: 1px solid #707070 !important;
        border: none;
        text-align: center;
        color: #EC743F;
        font-size: 16px;
        line-height: 15px;
        padding: 4px;
   }
   .login-input{
        outline: none;
   }
   .btn-container{
       width: 130px;
       height: 35px;
       border-radius: 100px;
       background-color: #1C1E3B;
       display: flex;
       align-items: center;
       justify-content: center;
       margin-top: 52px;
   }
  
   .resendotp-btn{
       background-color: #FFFFFF;
       border: 1px solid #1C1E3B;;
   }
   .resendotp-btn .login-btn{
        color: #1C1E3B;
        font-size: 16px;
        line-height: 19px;
        background-color: #FFFFFF;
    }

    // @media(max-width:600px){
    //     .LoginForm {
    //         right: 0%;
    //         background-color: #fff;
    //         margin-left: 0;
    //         box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    //         border-radius: 6px;
    //         opacity: 1;
    //         padding: 30px 10px 30px;
    //         margin-top: 0;
    //         border-radius: 40px 40px 0 0;
    //         position: fixed;
    //         bottom: 0px;
    //         left: 0;
    //         width: 100%;
    //         height: 400px;
    //     }
    //     .login-header {
    //         margin-top: 20px;
    //         max-height: 292px;
    //         height: 232px;
    //     }
    //     .login-box {
    //         width: 90%;
    //     }
    //     .login-p {
    //         margin-bottom: 15px;
    //     }

    //     .login-background {
    //         overflow: hidden;
    //         display: block;
    //         object-fit: cover;
    //         bottom: 0;
    //         position: fixed;
    //         left: 0;
    //         height: 100vh;
    
    //     }

    //     .bg-lightgrey {
    //         background-color: #F5F5F5;
    //         overflow: hidden;
    //     }


    // }

    @media(max-width:600px){
        .login-banner {
            display: none;
        }

        .mob-container {
            padding: 0px;
        }

        .login-box {
            width: 90%;
            margin: auto;
        }

        .LoginForm {
            background-color: #fff;
            opacity: 1;
            position: unset!important;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            justify-content: center;
            border-radius: 0px 40px 0px 40px!important;
            width: 390px;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            padding: 50px 10px 33px 10px;
            box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
            -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 5px 10px 0px rgb(0 0 0 / 10%);
            -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
            border-top: 4px solid #EC743F;
            border-bottom: 4px solid #EC743F;
            

            
        }

        .LoginForm-container {
            width: 100%;
            min-height: 92.2vh;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 15px;
            overflow: hidden;
            background-image: url("../../assets/Ugroimages/loginbg.webp");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            
        }

        .logo {
            width: 134px; 
            height: auto;
        }

        .login-header {
            margin-top: 15px;
        }

        .login-p {
            margin-bottom: 15px;
        }

        .top-heading {
            margin-top: 65px;
        }
    }
}